import {  useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../account/csgiaccount.css';
import '../account/Order.css';
import './Checkout.css';
import 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';
declare global {
    interface Window {
        ascendon: any;
        asc: any; // Add this line to declare the 'asc' object
    }
}
export const Checkout = () => {
    const navigate = useNavigate();
    const authToken = localStorage.getItem('authorizationToken') as string;
    useEffect(() => {
        fetch('/api/csgi/settings', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authToken
            }
        })
            .then(response => response.json())
            .then(data => {
                window.ascendon.service.settings.sessionId = data.sessionId;
                window.ascendon.service.settings.systemId = data.systemId;
                window.ascendon.service.settings.channelId = data.channelId;
                window.ascendon.service.settings.apiUrl = data.apiUrl;
                window.ascendon.service.settings.metadataUrl = data.metadataUrl;
                window.ascendon.service.settings.deviceType = data.deviceType;
                window.ascendon.service.settings.language = data.language;
                window.ascendon.orderManagement.configure({
                    container: 'orderDiv',
                    resourceLanguage: data.language
                });
                window.ascendon.orderManagement.show();
            })
            .catch(error => {
                console.log(error);
                navigate('/storefrontLogout');
                // window.location.href = './storefrontLogout';
            });


    }, [authToken]);
    window.ascendon.service.events.on('error', function (event: any, err: any) {
        if (err.Code === 109) {
            // window.location.href= './storefrontLogout';
            navigate('/storefrontLogout');
        }
    });
    window.ascendon.service.events.on('order.complete', function (event: any, submitOrderResponse: any) {
        if (submitOrderResponse) {
            window.ascendon.orderManagement.hide();
            $('#thanks').on('show.bs.modal', function (e) {
                $('#thanks').css('display', 'block').addClass('in');
            });
            $('#thanks').modal('show');
            // $.ajax({
            //     url: './logorder',
            //     type: 'post',
            //     headers: {
            //         "Authorization": authToken
            //     },
            //     data: JSON.stringify(submitOrderResponse),
            //     contentType: "application/json; charset=utf-8",
            //     dataType: "json"
            // });
        }
    });

    if (window.ascendon.orderManagement.events) {
        window.ascendon.orderManagement.events.on('cartEmpty', function () {
            window.location.reload();
        });
    }
    const goHome = () => {
        // window.location.href = '/home';
        navigate('/home');
    }
    return (
        <div id="upgradecontent">
            <div id="thanks"  className="modal fade" data-backdrop="static" style={{display:"none"}}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                                <button aria-hidden="true" className="close"  type="button" onClick={goHome}>×</button>
                            <h4 className="modal-title">You're all set!  Thank you for your purchase.</h4>
                        </div>
                            <div className="modal-body" id="modalBody"><b>Thank you for your purchase. An order confirmation will be sent to your email address shortly.</b>
                                <br/> <br/>Please note, if you purchased a video upgrade, it may take up to 1 hour for your channels to appear in your lineup. Additionally, we suggest clearing your browser cache. If you have any questions about your order or bill, please submit a ticket at <Link to="/help">http://support.xfinityoncampus.com</Link>
                                <br/> <br/>If you purchased a WiFi speed upgrade, it may take up to 24 hours for the upgrade to become available. If you have any questions about your WiFi upgrade, please visit <Link to="/help">http://support.xfinityoncampus.com</Link>
                            </div>
                        <div className="modal-footer">
                        <button className="btn btn-primary btn-lg" onClick={goHome} type="button">Continue</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Widget will be drawn in this container.  Note the ascendon css class, it is required. */}
            <div className="ascendon">
                <div id="progress"></div>
                <div id="orderDiv" style={{marginTop:"72px", marginLeft: "25px", marginRight: "25px"}}></div>
            </div>
            
        </div>
    );
};
