import React, { useEffect, useState } from 'react';
import Select, { components }from 'react-select';
import "./Login.css";
type OptionType = {
    value: string;
    label: string;
};
const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <i className="icon-search-black"></i>
        </components.DropdownIndicator>
    );
};

export const Login = () => {
    // const fedxUrl = process.env.REACT_APP_FEDX_URL;
    // console.log('fedxUrl:', fedxUrl)
    const [options, setOptions] = useState<OptionType[]>([]);
    const [inputValue, setInputValue] = useState('');
    let continueUrl = window.location.protocol+ "//" + window.location.hostname;
    if(window.location.hostname === 'localhost'){
        continueUrl = window.location.protocol+ "//" + window.location.hostname +":"+window.location.port;
    }
    useEffect(() => {
            fetch("/api/login?storefrontUrl="+encodeURIComponent(continueUrl))
                .then(response => response.json())
                .then(data => {
                    let partnerList = data.partnerList;
                    console.log('data:', partnerList)
                    setOptions(partnerList.map((item: any) => ({
                        value: item.authUrl,
                        label: item.name
                    }))
                    )
                });
    }
    ,[continueUrl]);

  return (
    <main className="main-content" role='main'>
        <section className="hero-component login">
            <h2>Welcome to Xfinity On Campus</h2>
            <p>Enjoy all your favorite channels included with your on-campus housing. Get TV on your terms, from premium shows to nonstop live sports. All from Xfinity.</p>
            <form id="fedxLogin" method="GET">
                <Select
                                options={inputValue.length >= 2 ? options : []}
                                isSearchable
                                components={{ DropdownIndicator }}
                                placeholder="Search for your school ..."
                                onInputChange={value => setInputValue(value)}
                                onChange={(option) => {
                                    console.log('option:', option)
                                    if (option) {
                                        const totalUrl = option.value;
                                        window.location.href=totalUrl;
                                    }
                                }}
                                styles={{ 
                                    container: (base) => ({ ...base, width: "500px"}),
                                    singleValue: (base) => ({ ...base, color: "grey", textAlign: "left" , paddingLeft: "20px" }),
                                    placeholder: (base) => ({ ...base, color: "grey", textAlign: "left" , paddingLeft: "25px" }),
                                    input: (base) => ({ ...base, paddingLeft: "20px" }),
                                    control: (base) => ({
                                        ...base,
                                        marginBottom: '-10px', // This reduces the gap between the dropdown and the search box
                                    }),
                                    option: (base) => ({ ...base, color: "grey", textAlign: "left" , paddingLeft: "20px" })
                                }}
                            />
            </form>
            <div>
            <br/>
                <p>
                    <span>Live Off-Campus?</span>
                    <br/>
                    <span>Check out exclusive student offers at </span><br/>
                    <a target="_blank" style={{fontWeight: "bold", color: "#84cbf6"}} href="https://www.xfinity.com/student">xfinity.com/student</a>
                </p>
            </div>
        </section>
    </main>
  );
}